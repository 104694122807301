<template>
  <v-container
    style="padding: 0px"
    class="mw-1150 d-flex justify-space-between"
  >
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <div class="mt-2">
        <BreadCrumbs :items="items"></BreadCrumbs>
      </div>
      <div class="align-center mb-7 mt-4">
        <Topic eng="Events and News" thai="กิจกรรมและข่าวสาร"></Topic>
      </div>

      <v-row class="mt-5">
        <v-col
          style="padding: 6px"
          v-for="item in eventAndNews"
          :key="item.id"
          md="3"
          sm="6"
          cols="12"
        >
          <NewsPanel
            :model="item"
            :image="item.image"
            :topic="item.name_events_news"
            :content="item.content"
            :date="item.date_events"
          ></NewsPanel>
        </v-col>
        <v-col cols="12">
          <v-pagination
            class="my-10"
            v-model="paginate.page"
            :length="paginate.pageCount"
            circle
            @input="changePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Topic from "../../components/Topic.vue";
import Loading from "../../components/Loading.vue";
import BreadCrumbs from "../../components/BreadCrumbs.vue";
import NewsPanel from "../../components/News/NewsPanel.vue";
import { mapState } from "vuex";
export default {
  components: {
    BreadCrumbs,
    Loading,
    Topic,
    NewsPanel,
  },
  computed: {
    ...mapState({
      eventAndNews: (state) => state.EventAndNews.eventAndNewsList,
      paginate: (state) => state.EventAndNews.paginate,
      access_token: (state) => state.auth.access_token,
    }),
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("EventAndNews/get", {
      row: this.row,
    });
    this.isLoading = false;
  },
  methods: {
    async changePage(val) {
      this.isLoading = true;
      await this.$store.dispatch("EventAndNews/get", {
        page: val,
        row: this.row,
      });
      this.isLoading = false;
    },
  },
  data() {
    return {
      row: 12,
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "กิจกรรมและข่าวสาร",
          disabled: true,
        },
      ],
      isLoading: false,
    };
  },
};
</script>

<style></style>
